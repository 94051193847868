#root {
    overflow-x: hidden;
}

body {
    background: rgb(47, 151, 217);
    background: linear-gradient(to bottom right, rgba(47, 151, 217, 1) 0%, rgba(63, 201, 236, 1) 10%, rgba(19, 58, 114, 1) 100%);
    min-height: 100vh;
    margin: 0;
    padding-bottom: 0;
}

/* width */
::-webkit-scrollbar {
    width: 0.425rem;
}
/* Track */
::-webkit-scrollbar-track {
    background-color: #ececee;
}
/* Handle */
::-webkit-scrollbar-thumb {
    background-color: #3f50b5;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background-color: #757ce8;
}